import React from "react";
import { Card, Button, Badge, Grid, Col, Text, Title } from "@tremor/react";
import { useAuth } from "../../auth/AuthContext";
import PulseLoader from "react-spinners/PulseLoader";
import {LockClosedIcon,PlusIcon,} from "@heroicons/react/24/outline";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import SalaryPassCard from "./SalaryPassCard"
import 'dayjs/locale/sv';
import {holidays} from "../../lib/utils";
import { Shift } from "../../types/shifts";
import { Month } from "../../types/salaryData";


dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.locale('sv');


type SalaryDetailsProps = {
  data: Month[][],
  onToggle: (id:string) => void,
  lockPasses: () => void,
  inspectShift: (id:string) => void,
  closeInspectShift: () => void,
  selectedShiftId: string | null,
  isLockingPasses: boolean,
  addShift: (shift:Shift) => void,
  driverNo: number | null,
  addCustomShift: (driverNo:number) => void
}

const SalaryDetails = (props:SalaryDetailsProps) => {

  const {
    data,
    onToggle,
    lockPasses,
    inspectShift,
    closeInspectShift,
    selectedShiftId,
    isLockingPasses,
    addShift,
    driverNo,
    addCustomShift
  } = props;

  const { user } = useAuth();

  if (!data) return null;


  const groupDataByDate = (data:Month[][]): Record<string, Month[]> => {
    return data.reduce<Record<string, Month[]>>((acc, line) => {
      line.forEach((item) => {
        if (item.datestring) {
          const parsedDate = dayjs(item.datestring, "DD-MM-YYYY");
          const formattedDate = parsedDate.format("YYYY-MM-DD");
          if (!acc[formattedDate]) {
            acc[formattedDate] = [];
          }
          acc[formattedDate].push(item);
        }
      });
      return acc;
    }, {});
  };

  const findFirstValidDate = (data:Month[][]) => {
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data[i].length; j++) {
        if (data[i][j].datestring) {
          return data[i][j].datestring;
        }
      }
    }
    return null;
  }


  const groupedData = groupDataByDate(data);
  const firstValidDateString = findFirstValidDate(data);

  if (!firstValidDateString) {
    return <div>No valid date found in the data</div>;
  }

  const firstItemDate = dayjs(firstValidDateString, "DD-MM-YYYY");
  const month = firstItemDate.month(); // Note: month() is zero-indexed (0 for January, 11 for December)
  const year = firstItemDate.year();

  // Generate all dates in the month using dayjs
  const startOfMonth = dayjs().year(year).month(month).startOf('month');
  const endOfMonth = dayjs().year(year).month(month).endOf('month');

  const allDates = [];
  for (let date = startOfMonth; date.isBefore(endOfMonth) || date.isSame(endOfMonth); date = date.add(1, 'day')) {
    allDates.push(date);
  }


  return (
    <div className="flex flex-col pb-12">
      <Title className="">Redovisade pass</Title>
       {user && (user.rights === 2 || user.rights === 1) ? (
        <div className="flex flex-row justify-between items-center">
          <div className="mt-4 mb-4">
            <Button
              icon={LockClosedIcon}
              size="xs"
              onClick={() => lockPasses()}
            >
              {isLockingPasses ? (
                <PulseLoader color="#36d7b7" size={3} />
              ) : (
                "Lås alla pass"
              )}
            </Button>
          </div>
          <div className=" mt-4 mb-4">
            <Button
              icon={PlusIcon}
              color={"green"}
              size="xs"
              onClick={() => driverNo && addCustomShift(driverNo)}
            >
              {isLockingPasses ? (
                <PulseLoader color="#36d7b7" size={3} />
              ) : (
                "Lägg till pass"
              )}
            </Button>
          </div>
        </div>
      ) : null}

    <div className="mx-auto w-full">
        {allDates.map((date) => {
          const dateString = date.format("YYYY-MM-DD");
          const weekday = date.format("dddd");
          const items = groupedData[dateString] || [];
          const isEmpty = items.length === 0;
          const isWeekend = weekday === "lördag" || weekday === "söndag";
          const holidayDateFormat = date.format("DD-MM-YYYY");
          const isHoliday = holidays.includes(holidayDateFormat);
          const bgColorClass = (Number(date.format("DD")) % 2 === 0) ? 'bg-slate-100' : 'bg-white';

          return (
            <Card key={dateString} className={`my-3 pb-1 tremor-small pt-4 ${bgColorClass}`}>
              <div className="text-sm font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong mb-0">
                <Text
                  className={
                    "font-bold capitalize " +
                    (isWeekend || isHoliday
                      ? "text-rose-400"
                      : "text-slate-500")
                  }
                >
                  {dateString} 
                  <span className="pl-3">
                    {weekday.charAt(0).toUpperCase() + weekday.slice(1)}
                  </span>
                </Text>
              </div>
              <div className={`leading-6 pb-5 pt-4 ${isEmpty ? 'sm:pb-2.5 sm:pt-0' : ''}`}>
                <Grid numItems={1} className="gap-2">
                  {items.length > 0 ? (
                    items.map((item, index) => (
                      <SalaryPassCard
                        key={index}
                        item={item}
                        user={user}
                        onToggle={onToggle}
                        inspectShift={inspectShift}
                      />
                    ))
                  ) : (
                    <></>
                  )}
                </Grid>
              </div>
            </Card>
          );     
        })}
        </div>
      </div>
    );
  };

export default SalaryDetails;
