import React from 'react';
import {Table, TableBody, TableCell, TableHead, TableRow, Text, TableHeaderCell, Title} from '@tremor/react';
import { formatDate, getReportType } from '../../lib/utils';
import { BuildingOfficeIcon, CalendarIcon, TruckIcon, UserIcon, WrenchIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { useAuth } from '../../auth/AuthContext';
import { Shift } from '../../types/shifts';

type ShiftTableProps = {
    shifts: Shift[]
    /* title:string, */
}

const ShiftTable = (props:ShiftTableProps) => {

    const {
        shifts,
       /*  title */
    } = props

    const {user} = useAuth()
    
    if(!shifts) return null;

    return (
        <>
            <Table className="mb-8 mt-8">
                <TableHead>
                    <TableRow>
                    <TableHeaderCell>
                        <CalendarIcon className="h-4 w-5 mr-2 inline-block" />
                        Datum 
                    </TableHeaderCell>
                    <TableHeaderCell>
                        <TruckIcon className="h-4 w-5 mr-2 inline-block" />
                        Bil
                    </TableHeaderCell>
                    {
                        (user && user.rights === 5) ? null : (
                            <TableHeaderCell>
                                <UserIcon className="h-4 w-5 mr-2 inline-block" />
                                Förare
                            </TableHeaderCell>
                        )
                    }
                    <TableHeaderCell>
                        <BuildingOfficeIcon className="h-4 w-5 mr-2 inline-block" />
                        Företag
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        <WrenchIcon className="h-4 w-5 mr-2 inline-block" />
                        Slutrapport
                    </TableHeaderCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {shifts.map((item,idx) => (
                    <TableRow key={idx}>
                        <TableCell>
                            { (!item.startdate) ? "" : formatDate(item.startdate.toString().split('T')[0])}
                        </TableCell>
                        <TableCell>
                            <Text>{item.carNo || '-'}</Text>
                        </TableCell>
                        {
                            (user && user.rights === 5) ? null : (
                                <TableCell>
                                    <Text>{item.username || '-'}</Text>
                                </TableCell>
                            )
                        }
                        <TableCell>
                            <Text>{item.haldaName}</Text>
                        </TableCell>
                        <TableCell className='text-center'>
                            <Link className={`${ (item.workshiftNo) ? 'text-blue-500 underline' : 'text-slate-500 italic underline' }`} to={`/app/shift/${item.Id}`}>{item.workshiftNo || getReportType(item['report_type'])}</Link>
                        </TableCell>
                    </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
}

export default ShiftTable;