import React,{useState,useEffect} from "react";
import axios from "axios";
import BreaksTable from "../BreaksTable";
import dayjs from "dayjs";
import { useAuth } from '../../auth/AuthContext'
import { 
    Title, 
    Button, 
    Grid, 
    Col,
    Divider,
    Text
} from "@tremor/react";
import { formatTime, calculateTotalHours, formatDate, getReportType} from "../../lib/utils";
import { Shift,Report } from "../../types/shifts";
import { Company } from "../../types/company";
// import FileUploader from "../FileUploader";
type onChangeType = string | number |boolean | null | undefined;
type RegisteredShiftFormProps = {
    shift : Shift,
    onChange:(value: onChangeType, name: keyof Report) => void,
    updateShift: () => void,
    createShift: () => void,
    removeShift: () => void,
    removeBreak: (breakID:number) => void,
    removeAttachment: (type:string,id:string) => void,
    success: () => void,

}

const RegisteredShiftForm = (props:RegisteredShiftFormProps) => {

    const {
        shift,
        onChange,
        updateShift,
        createShift,
        removeShift,
        removeBreak,
        removeAttachment,
        success
    } = props
    
    const {report,breaks,registered} = shift;
    const {user} = useAuth();

    const [startdate,setStartDate] = useState(dayjs(report.startdate).format('YYYY-MM-DD') || dayjs().format('YYYY-MM-DD') );
    const [company,setCompany] = useState<Company | null >(null);
    const [hoursWorked,setHoursWorked] = useState<number | null>(null);
    
      useEffect(() => {
        let total = 0;
        if (shift.report.starttimeUI && shift.report.endtimeUI) {
          console.log(shift);
          total = calculateTotalHours(shift);
        }
    
        setHoursWorked(total);
      }, [shift]);
    

    useEffect(()=>{
        const fetchCompanies = async () => { 
            try{
                if(!startdate)
                    return;
                
                const response = await axios.get(`/company/${report.akareNo}`);
                if(response.status !== 200) {
                    console.log(`Request failed with respone status ${response.status}`)
                }
                setCompany(response.data);
            }   catch(err){
                console.log(err);
            }
            
        };
        fetchCompanies();
    },[])

    if(!report)
        return null;

    return (
        <div className="pb-12">
            <Grid numItems={4} className="gap-2">
                <Col numColSpan={1}>
                    <div className="mt-2 mb-2 mr-6">
                        <Text>Start Date</Text>
                        <Text>{formatDate(report.startdate?.toString() ?? "")}</Text>
                    </div>
                </Col>
                <Col numColSpan={1}>
                    <div className="mt-2 mb-2 mr-6">
                        <Text>EndDate</Text>
                        <Text>{formatDate(report.enddate?.toString() ?? "")}</Text>
                    </div>
                </Col>
                <Col numColSpan={1}>
                    <div className="mt-2 mb-2 max-w-sm">
                        <Text>Start:</Text>
                        <Text>{formatTime(report.starttimeUI?.toString() ?? "")}</Text>
                    </div>
                </Col>
                
                <Col numColSpan={1}>
                    <div className="mt-2 mb-2 max-w-sm">
                        <Text>Slut:</Text>
                        <Text>{formatTime(report.endtimeUI?.toString() ?? "")}</Text>
                    </div>
                </Col>
                <Col numColSpan={1}>
                    <Text className="text-md">Company:</Text>
                    <Text>{company ? company.haldaName : ""}</Text>
                  
                </Col>
                <Col numColSpan={2}>
                    <div className="mt-2 mb-2">
                        <Text>Aktivitet:</Text>
                        <Text>{report.comment}</Text>
                    </div>
                </Col>
                <Col numColSpan={1}>
                  <div className="mt-2 mb-2">
                    <Text className="font-bold">Typ:</Text>
                    <Text>{getReportType(report?.report_type!)}</Text>
                  </div>
              </Col>
               
                
                <Col numColSpan={4}>
                    <div className="mt-2 mb-2">
                        <Text>Arbetad tid:</Text>
                        {/* <TextInput value={calculateTotalHours(shift)} disabled /> */}
                        <Text>{hoursWorked}</Text>
                    </div>
                </Col>

                <Col numColSpan={4} >
                    <Divider>Rast</Divider>
                </Col>
                <Col numColSpan={4}>
                    {/* <Title className="mt-4">Rast</Title> */}
                    {
                        <BreaksTable breaks={breaks} removeBreak={removeBreak} locked={user && ((report.locked || registered) && user.rights===5)} />
                    }
                </Col>
                <Col numColSpan={4} className="mt-3 mb-3">
                    <Divider>Rapportering</Divider>
                </Col>   
                <Col numColSpan={4} >
                    <label className="text-slate-600">Avvikelser / Felrapportering</label>
                    <div className="mt-4 mb-6">
                        <Text>Ekonomi/Lön/ADM :</Text>
                        <Text>{report.errorReportAdm}</Text>
                    </div>
                    {
                            (report.errorImgAdm) ? (
                                <>
                                    <img alt="img" src={`http://localhost:3005/api/uploads/`+report.errorImgAdm} className="w-[92px] border " />
                                    <Button className="mt-4" onClick={()=>removeAttachment('adm',report.ID ?? "NO ID")}>Ta bort</Button>
                                </>
                            ): null
                        }
                </Col>

                
                <Col numColSpan={4} >
                    <label className="text-slate-600">Felanmälan</label>
                    <div className="mt-4 mb-6">
                        <Text>Fordon / Teknik / Tvätthall :</Text>
                        <Text>{report.errorReportVehicle}</Text>
                        {
                            (report.errorImgVehicle) ? (
                                <>
                                    <img alt="img" src={`http://localhost:3005/api/uploads/`+report.errorImgVehicle} className="w-[92px] border " />
                                    <Button className="mt-2" onClick={()=>removeAttachment('vehicle',report.ID ?? "NO ID")}>Ta bort</Button>
                                </>
                            ): null
                        }
                    </div>
                </Col>
                <Col numColSpan={4} >
                    <div className="flex flex-row justify-end">
                        {
                            user && (report.driverReported && (user.rights === 2 || user.rights === 1 )) ? 
                            <Button className="mr-4" color="rose" onClick={removeShift}>Ta bort</Button> : null
                        }
                        {
                           user && (!report.locked && (user.rights === 2 || user.rights === 1 )) ? (
                                <Button onClick={updateShift} >Approve</Button>
                            ) : null
                        }
                    </div>
                </Col>
            </Grid>
        </div>
    );
};

export default RegisteredShiftForm;

