import React from "react";
import { Grid, Col , Divider} from "@tremor/react";
import CardLineChart from "./LineChart";
import TotalMetric from "./TotalMetric";
import { convertToCurrency } from "../../lib/utils";
import { VehicleStats,VehicleStatsOverall } from "../../../../shared/types/vechileStats";

type UserViewProps = {
    statsData: VehicleStats | null,
    driverNo: number | null,
    carNo: number | null,
    moms: boolean
}

const UserView = (props:UserViewProps) => {

    const {
        statsData,
        driverNo,
        carNo,
        moms
    } = props;


    if(!statsData)
        return null;

    return (
        <div className="w-full">
            <Divider>Inkört belopp</Divider>
            <div className="mt-4 mb-4">
                <CardLineChart index={"dag"} title={`Inkört ${ (moms) ? 'Inkl. moms' : 'Exkl. moms'}`} labels={[(moms) ? "Inkört inkl moms" : "Inkört exkl moms"]} data={statsData?.totalMoney} />
                <div className="flex flex-wrap">
                    <div className="w-1/2 p-2">
                        <TotalMetric 
                            value={(moms) ? convertToCurrency(statsData?.totalMoneyTotalInk) : convertToCurrency(statsData?.totalMoneyTotal)} 
                            title={"Total Inkört"} 
                            undertext={`${(moms) ? 'Inkl. moms' : 'Exkl. moms'}`} 
                        />
                    </div>
                    <div className="w-1/2 p-2">
                        <TotalMetric 
                            value={(moms) ? convertToCurrency(statsData?.totalMoneyHardCash) : convertToCurrency(statsData?.totalMoneyHardCash / 1.06)} 
                            title={"Total Kontanter"} 
                            undertext={`${(moms) ? 'Inkl. moms' : 'Exkl. moms'}`} 
                        />
                    </div>
                </div>        
            </div>
            
            <Divider>Sträcka</Divider>
            <div className="mt-4 mb-4">
                <CardLineChart index={"dag"} title={`Totalt antal mil`} labels={["Total antal mil", "Betalda mil", "Obetalda mil"]} data={statsData?.totalDriven} />
                <Grid numItems={2}>
                    <Col numColSpan={1}>
                        <TotalMetric value={statsData.totalDrivenTotals.total   + " mil"} title={"Totala antal mil i period"}/>
                    </Col>
                    <Col numColSpan={1}>
                        <TotalMetric value={statsData?.totalDrivenTotals.paid  + " mil"} title={"Totala betalda mil i period"}/>
                    </Col>
                    <Col numColSpan={1}>
                        <TotalMetric value={statsData?.totalDrivenTotals.unpaid + " mil"} title={"Totala obetalda mil i period"}/>
                    </Col>
                    <Col numColSpan={1}>
                        <TotalMetric value={(moms) ? convertToCurrency(statsData?.totalPerMileInkl) : convertToCurrency(statsData?.totalPerMile) } title={"Kr / mil"} undertext={`${ (moms) ? 'Inkl. moms' : 'Exkl. moms'}`}/>
                    </Col>
                </Grid>
            </div>
            
            <Divider>Tider</Divider>
            <div className="mt-4 mb-4">
                <CardLineChart index={"dag"} title="Total Tid (Timmar)" labels={["Arbetade timmar", "Rasttid"]} data={statsData?.totalTime} />
                <Grid numItems={3}>
                    <Col numColSpan={1}>
                        <TotalMetric value={statsData?.totalTimeTotals.hoursWorked + " tim"} title={"Total arbetstid i period"}/>
                    </Col>
                    <Col numColSpan={1}>
                        <TotalMetric value={statsData?.totalTimeTotals.hoursRested + " tim"} title={"Total Rast"}/>
                    </Col>
                    <Col numColSpan={1}>
                        <TotalMetric value={(moms) ? convertToCurrency(statsData?.totalPerHoursInkl) : convertToCurrency(statsData?.totalPerHours) } title={"Total per tim"} undertext={`${ (moms) ? 'Inkl. moms' : 'Exkl. moms'}`}/>
                    </Col>
                </Grid> 
            </div>

            <Divider>BOM</Divider>
            <div className="mt-4 mb-4">
                <TotalMetric value={ (moms) ? convertToCurrency(statsData?.totalBom) : convertToCurrency(statsData?.totalBom-(statsData?.totalBom*0.06))} title={"Total Bom"} undertext={`${ (moms) ? 'Inkl. moms' : 'Exkl. moms'}`}/>
            </div>

            {
                (carNo) ?
                    <>
                        <Divider>Special</Divider>
                        <div className="mt-4 mb-4">
                            <TotalMetric value={statsData?.totalDistanceBetweenShifts+ " km"} title={"Total Between Shifts"}/>
                        </div>
                    </>    
                : null
            }            
            
    </div>
    );
}

export default UserView;
